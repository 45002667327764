<template>
  <div>
    <MDBDropdown btnGroup v-model="dropdown">
      <MDBBtn @click="darkLightChange" color="primary" size="sm">
        {{ $t("ThemeSwitcher.button.text") }}
      </MDBBtn>
      <MDBDropdownToggle split size="sm" @click="dropdown = !dropdown" />
      <MDBDropdownMenu>
        <MDBDropdownItem href="#">
          <MDBRadio
            :label="$t('ThemeSwitcher.dropdown.default')"
            name="radioDefault"
            v-model="appTheme"
            value="default"
          />
        </MDBDropdownItem>
        <MDBDropdownItem href="#">
          <MDBRadio
            :label="$t('ThemeSwitcher.dropdown.light')"
            name="radioLight"
            v-model="appTheme"
            value="light"
          />
        </MDBDropdownItem>
        <MDBDropdownItem href="#">
          <MDBRadio
            :label="$t('ThemeSwitcher.dropdown.dark')"
            name="radioDark"
            v-model="appTheme"
            value="dark"
          />
        </MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>
  </div>
</template>

<script setup>
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn,
  MDBRadio,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

const dropdown = ref(false);

import Setup from "./ThemeSwitcherSetup";

const { appTheme, darkLightChange } = Setup();
</script>
