<template>
  <div>
    <MDBDropdown btnGroup v-model="dropdown">
      <MDBDropdownToggle
        size="sm"
        @click="dropdown = !dropdown"
        color="primary"
      >
        {{ $t("LangSwitcher.button.text") }}&nbsp;
      </MDBDropdownToggle>
      <MDBDropdownMenu>
        <MDBDropdownItem href="#">
          <MDBRadio
            :label="$t('LangSwitcher.dropdown.english')"
            name="radioDefault"
            v-model="lang"
            value="en"
          />
        </MDBDropdownItem>
        <MDBDropdownItem href="#">
          <MDBRadio
            :label="$t('LangSwitcher.dropdown.french')"
            name="radioDefault"
            v-model="lang"
            value="fr"
          />
        </MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>
  </div>
</template>

<script setup>
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBRadio,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

const dropdown = ref(false);

import Setup from "./LangSwitcherSetup";

const { lang } = Setup();
</script>