<template>
  <MDBContainer>
    <div class="gradient-box rounded-3 w-fit m-auto">
      <form class="rounded-2 p-3 bg-box">
        <!-- Email input -->
        <MDBInput
          type="text"
          autocomplete="UserName"
          :label="$t('Connexion.email.label')"
          id="UserName"
          v-model="UserName"
          wrapperClass="mb-4"
          class="text-center"
        />
        <!-- Password input -->
        <MDBInput
          type="password"
          autocomplete="current-password"
          :label="$t('Connexion.password.label')"
          id="Password"
          v-model="Password"
          wrapperClass="mb-4"
          class="text-center"
        />
        <!-- Forgot ? -->
        <MDBRow class="mb-4">
          <MDBCol>
            <div class="w-fit mx-auto">
              <a href="#!"> {{ $t("Connexion.forgot") }} </a>
            </div>
          </MDBCol>
        </MDBRow>
        <!-- Submit button -->
        <MDBBtn @click="submit" color="primary" block>
          {{ $t("Connexion.submit") }}
        </MDBBtn>
      </form>
    </div>
  </MDBContainer>
</template>

<script setup>
import { MDBRow, MDBCol, MDBContainer, MDBInput, MDBBtn } from "mdb-vue-ui-kit";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

const UserName = ref("");
const Password = ref("");

const submit = async () => {
  await store
    .dispatch("login", { UserName: UserName.value, Password: Password.value })
    .then(() => {
      router.push({
        name: "CheckingsList",
      });
    });
};
</script>

<style lang="scss" scoped>
form {
  min-width: 300px;
  width: 350px;
  max-width: 90vw;
}
</style>