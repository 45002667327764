import { computed } from 'vue'
import router from '@/router'
import { Trans } from '@/plugins/translation'

export default function Setup() {
  const lang = computed({
    get: () => {
      return Trans.currentLanguage
    },
    set: (value) => {
      Trans.changeLanguage(value).then(() => router.push(Trans.i18nRoute(router.currentRoute._value.path)))
    }
  })

  return {
    lang,
  };
}
