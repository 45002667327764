<template>
  <img :src="Link" :alt="$t('Brand.alt')" />
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "vuex";
const props = defineProps({
  size: String,
});
const store = useStore();

const Link = computed(() => {
  let link = "https://eecheck.app/wp-content/uploads/2020/05/eecheck_logo";
  if (props.size === "mini") link += "miniature_";
  store.state.app.theme === "dark" ||
  (store.state.app.theme === "default" &&
    store.state.app.defaultTheme === "dark")
    ? (link += "fond_couleur")
    : (link += "fond_clair");
  if (props.size && props.size !== "mini") link += "@" + props.size;
  return link + ".png";
});
</script>