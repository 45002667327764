<template>
  <MDBNavbar>
    <MDBNavbarNav class="position-relative w-100 flex-row mb-2">
      <Brand
        :size="smallScreen ? 'mini' : ''"
        class="position-absolute top-0 left-0 header-brand ms-3 above"
      />
      <div class="flex-grow-1"></div>
      <MDBNavbarItem>
        <ThemeSwitcher class="w-fit mt-2 me-2 ms-auto" />
      </MDBNavbarItem>
      <MDBNavbarItem>
        <LangSwitcher class="w-fit mt-2 me-2 ms-auto" />
      </MDBNavbarItem>
    </MDBNavbarNav>
  </MDBNavbar>
</template>

<script setup>
import Brand from "@/components/common/Brand";
import LangSwitcher from "@/components/ui/LangSwitcher";
import ThemeSwitcher from "@/components/ui/ThemeSwitcher";
import { MDBNavbar, MDBNavbarNav, MDBNavbarItem } from "mdb-vue-ui-kit";

import { computed } from "vue";
const smallScreen = computed(() => {
  return window.matchMedia("(max-width: 768px)").matches;
});
</script>

<style lang="scss">
.header-brand {
  height: 40px;
}
</style>